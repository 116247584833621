
<template class="">
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2 ">
			<v-layout row wrap class="ma-3 no-print">
				<v-flex xs6 md3 lg3 xl3 class="mb-2">
					<v-btn @click="readOrderItems('purchased')" color="blue">Purchased</v-btn> </v-flex>
				<v-flex xs6 md3 lg3 xl3 class="mb-2"><v-btn @click="readOrderItems('packed')" color="green">Packed</v-btn> </v-flex>
				<v-flex xs6 md3 lg3 xl3 class="mb-2"><v-btn @click="readOrderItems('pending')" color="orange">Pending</v-btn> </v-flex>
				<v-flex xs6 md3 lg3 xl3 class="mb-2"><v-btn @click="readOrderItems('soldout')" color="gray">Soldout</v-btn> </v-flex>
				<v-flex xs6 md3 lg3 xl3 class="mb-2"><v-btn @click="readOrderItems('soldout_told')" color="pink accent-1">soldout Told</v-btn> </v-flex>
				<v-flex xs6 md3 lg3 xl3 class="mb-2"><v-btn @click="readOrderItems('incomplete')" color="red">Incomplete</v-btn> </v-flex>
				<v-flex xs6 md3 lg3 xl3 class="mb-2"><v-btn @click="readOrderItems('delivered')" color="gray">Delivered</v-btn> </v-flex>
				<v-flex xs6 md3 lg3 xl3 class="mb-2"><v-btn @click="readOrderItems('all')" color="gray">ALL</v-btn> </v-flex>
			</v-layout>
	
			<v-layout row wrap mt-5 class="">
				<v-flex xs12 class="no-print ">
					<h1 class="ma-3">Search</h1>
					<v-text-field v-model="search" class="ma-3" append-icon="mdi-magnify" label="search SKU" dense outlined hide-details></v-text-field>
                    <v-autocomplete
                        v-model="customer"
                        :items="customerList"
                        item-text="customer_username"
                        item-value="customer_username"
                        label="Customer"
                        dense
                        outlined
                        hide-details
                        class="ma-3"
                        clearable

                    ></v-autocomplete>
                                         <v-btn @click="readCustomers()"   class="ma-3" color="primary">Get Customers</v-btn>

					<v-card>
						<v-card-text>
							<v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="order_item_id">
								<template v-slot:[`item.order_item_price`]="{ item }">
									<div>
										$ {{ item.order_item_price }}
									</div>
								</template>
								<!-- template v-odel for order_item_status -->
								<template v-slot:[`item.order_item_status`]="{ item }">
									<div>
										<v-chip v-if="item.order_item_status == 'pending'" color="orange" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'packed'" color="green" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'incomplete'" color="red" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'purchased'" color="blue" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'soldout'" color="black" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'postponed'" color="black" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'delivered'" color="gray" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'soldout_told'" color="pink accent-1" dark>{{ item.order_item_status }}</v-chip>
									</div>
								</template>
								<!-- create a v-select for the order_item_status -->
								<!-- template for the order_item_link on click go to the link -->
								<template v-slot:[`item.order_item_link`]="{ item }">
									<div>
										<a :href="item.order_item_link" target="_blank">
											<v-tooltip bottom>
												<template v-slot:activator="{ on }">
													<v-icon v-on="on" v-if="item.order_item_link != null">mdi-link</v-icon>
													<v-icon v-if="item.order_item_link == null" color="red" v-on="on">mdi-link</v-icon>
												</template>
												<span>{{ item.order_item_link }}</span>
											</v-tooltip>

										</a>
									</div>
								</template>
								<!-- order_item_date format date and time -->
								<template v-slot:[`item.order_item_date`]="{ item }">
									<div>
										{{ new Date(item.order_item_date).toISOString().split('T')[0]}} {{ new Date(item.order_item_date).toISOString().split('T')[1].split('.')[0]}}
									</div>
								</template>
								<template v-slot:[`item.order_item_purchase_date`]="{ item }">
									<div v-if="item.order_item_purchase_date!=null">
										{{ new Date(item.order_item_purchase_date).toISOString().split('T')[0]}} {{ new Date(item.order_item_purchase_date).toISOString().split('T')[1].split('.')[0]}}
									</div>
								</template>

								<template v-slot:[`item.order_item_id`]="{ item }">
									<div>
										
										<v-btn color="primary" @click="selectOrderItems(item)" class="mx-1" icon><v-icon>mdi-bag-personal</v-icon></v-btn>

										<v-btn color="primary" @click="selectOrderItemsForUpdate(item)" v-if="item.order_item_status =='purchased' || item.order_item_status =='packed'" class="mx-1" icon><v-icon>mdi-list-status</v-icon></v-btn>
									</div>
								</template>

							</v-data-table>
						</v-card-text>

						
					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<!-- create a dialog for showUpdateDialog -->
		<v-dialog v-model="showUpdateDialog">
			<v-card>
				<v-card-title>
					Sku Code : {{item_update.order_sku_code}}

				</v-card-title>
				<v-card-text>
					<v-layout row wrap>

						<!-- <v-flex xs12>
							<v-text-field v-model="item_update.order_item_id" label="Order Item Price" type="number" outlined dense></v-text-field>
						</v-flex>
						<v-flex xs12>
							<v-text-field v-model="item_update.order_item_status" label="Order Item Link" type="text" outlined dense></v-text-field>
						</v-flex> -->
						<!-- create radio buttons for status -->
						<v-flex xs12>
							<v-radio-group v-model="item_update.order_item_status" row>
								<!-- <v-radio label="pending" value="pending"></v-radio> -->
								<v-radio label="packed" value="packed"></v-radio>
								<!-- <v-radio label="incomplete" value="incomplete"></v-radio> -->
								<v-radio label="purchased" value="purchased"></v-radio>
								<!-- <v-radio label="soldout" value="soldout"></v-radio> -->
								<!-- <v-radio label="postponed" value="postponed"></v-radio> -->
							</v-radio-group>
						</v-flex>

					</v-layout>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="updateOrderItemStatus">Update</v-btn>
					<v-btn color="error" @click="showUpdateDialog = false">Cancel</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="showDeliveryPrice">
			<v-card>
				<v-card-title>
					<p v-if="showDeliveryPrice ">Order ID : {{order[0].order_id}}</p>

				</v-card-title>
				<v-card-text>
					<v-layout row wrap>

						<!-- <v-flex xs12>
							<v-text-field v-model="item_update.order_item_id" label="Order Item Price" type="number" outlined dense></v-text-field>
						</v-flex>
						<v-flex xs12>
							<v-text-field v-model="item_update.order_item_status" label="Order Item Link" type="text" outlined dense></v-text-field>
						</v-flex> -->
						<!-- create radio buttons for status -->

						<v-flex xs12>
							<v-text-field v-model="delivery_price" label="Delivery Price" type="number" outlined dense></v-text-field>
						</v-flex>

					</v-layout>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="success" class="elevation-0" @click="addPackedOrders">
						Deliver Bag
					</v-btn>
					<v-btn color="warning" class="elevation-0" @click="addPackedOrdersDirect">
						Direct Bag
					</v-btn>
					<!-- <v-btn color="primary" @click="updateOrderItemStatus">Update</v-btn> -->
					<v-btn color="error" @click="showDeliveryPrice = false">Cancel</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showBagDialog" v-if="order.length > 0" fullscreen>
			<div>
				<v-card height="100vh">

					<div class="no-print">
						<v-card-title>
							Order Bag ( {{$store.getters.language.data.orders.order_date}}: {{new Date(order[0].order_date).toLocaleString()}} )
							<v-spacer></v-spacer>
							<v-btn color="success" class="elevation-0" disabled v-if="order_items_bag.length > 0 && order_items_bag.every(item => item.order_item_status != 'packed')">
								<v-icon>mdi-bag-carry-on-check</v-icon> Deliver Bag
							</v-btn>
							<v-btn color="success" class="elevation-0" @click="showDeliveryPrice = true" v-else>
								Deliver Bag
							</v-btn>
							<!-- <v-btn color="success" class="elevation-0" @click="showDeliveryPrice = true">
								Delivery Price
							</v-btn> -->
							<v-btn text class="elevation-0" @click="showBagDialog = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>

						</v-card-title>
					</div>
					<v-card-text class="">
						<div class="no-print">
							<!-- {{order}} -->
							<!--  show the order[0] infromations in a card -->
							<v-layout row wrap class="ma-3">

								<v-flex xs12 lg4 xl4 md4 sm4>
									<p class="font-weight-bold">{{$store.getters.language.data.customers.customer_username}}: {{order[0].customer_username}} </p>
								</v-flex>
                                <v-flex xs12 lg3 xl3 md4 sm5 v-if='order[0].order_note != null'>
									<h3 class="font-weight-bold" style="color: red"> {{$store.getters.language.data.orders.order_note}}: {{order[0].order_note}}</h3>
								</v-flex>
								<v-flex xs12 lg3 xl3 md3 sm4>
									<p class="font-weight-bold"> Entered By : {{order[0].user_username}}</p>
								</v-flex>
								<v-flex xs12 lg4 xl4 md4 sm4>
									<p class="font-weight-bold"> Customer Type : {{order[0].customer_type}}</p>
								</v-flex>
                                <v-flex>
                                    <p class="font-weight-bold"> City : {{order[0].customer_city}}</p>
                                </v-flex>
                                <v-flex xs12 lg3 xl3 md3 sm4>
                                    <p class="font-weight-bold"> Address : {{order[0].customer_address}}</p>
                                </v-flex>
								<v-flex xs12 lg2 xl2 md3 sm4>
									<p class="font-weight-bold"> {{$store.getters.language.data.orders.order_shipping_price}}: ${{order[0].order_shipping_price}}</p>
								</v-flex>
								
								<v-flex xs12 lg3 xl3 md4 sm5>
									<p class="font-weight-bold"> Total Packed Items : ${{total_bag_price ? total_bag_price.toFixed(2) : 0}}</p>
								</v-flex>
								<v-flex xs12 lg3 xl3 md4 sm5>
									<p class="font-weight-bold"> Total Shipping Price : ${{total_shipping_price}}</p>
								</v-flex>
								<v-flex xs12 lg3 xl3 md4 sm5>
									<p class="font-weight-bold"> Total Price : ${{total_price.toFixed(2)}}</p>
								</v-flex>
								<v-flex xs12 lg3 xl3 md4 sm5>
									<p class="font-weight-bold"> Total Price in IQD: {{total_price_dinar}} </p>
								</v-flex>
								<!-- show paregraph if it was packed otherwise show the button -->
								<!-- <v-flex xs12 lg2 xl2 md3 sm4>
									<p v-if='order[0].order_status == "packed"' style="color:green" class="font-weight-bold">{{$store.getters.language.data.orders.order_status}}: {{order[0].order_status}}</p>
									<p v-if='order[0].order_status == "unpacked"' style="color:red" class="font-weight-bold">{{$store.getters.language.data.orders.order_status}}: {{order[0].order_status}}</p>
								</v-flex> -->
							</v-layout>
							<!-- create a table for print invoice show data  -->
						</div>
						<v-layout>
							<v-flex xs12>

								<div class="only-print">
									<!-- <v-img src="../../assets/elly_invoice_logo.png" height="200px" contain></v-img> -->
									<!-- <h3 style="color:black" class="my-4">ID : {{packed_order_id_print}}</h3> -->
									<div class="text-center my-4">
										<p style="color:black">Arrival Date: {{arrival_date}}</p>
									</div>
									<table class="table " width="90%">

										<tbody width="100%">
											
									

											<tr>
												<td style="color:black"  class="font-weight-bold"><v-icon style="color:black">mdi-map-marker-outline</v-icon>
												{{ order[0].customer_city }}</td>
											</tr>
											<tr>
												
										<td style="color:black">
													<v-icon style="color:black">mdi-home-outline</v-icon>
										
												{{ order[0].customer_address }}</td>
											</tr>
                                            		<tr>
												
												<td style="color:black" class="font-weight-bold"><v-icon style="color:black">mdi-phone</v-icon>
                                                    {{ order[0].customer_phone }}</td>
											</tr>
                                            		<tr>
												
												<td style="color:black" class="font-weight-bold"><v-icon style="color:black">mdi-phone</v-icon>
                                                    {{ order[0].customer_phone_2 }}</td>
											</tr>
                                            <tr>
												<td class="font-weight-bold" style="color:black">IQD {{total_with_delivery.toLocaleString()}}</td>
											</tr>
											<tr>
												<!-- <th style="color:black">Customer Type</th> -->
												<td style="color:black">
                                                   <p class="my-0 py-0"> info</p>
                                                    <p class="my-0 py-0">
                                                        {{ order[0].customer_type }}<br>
                                                        $={{this.store_information[0].dollar_price}}
                                                        <br>qty {{qty}}
                                                    </p>
                                                </td>
											</tr>
										
                                            <tr>
												<!-- <th style="color:black">Username</th> -->
												<td style="color:black" class="font-weight-bold"><v-icon style="color:black">mdi-account-outline</v-icon>
                                                    {{ order[0].customer_username }}</td>
											</tr>
										

										</tbody>
									</table>
									<!-- div center item inside -->

									<div class="text-center mt-4">
										<barcode :value="packed_order_id_print" format="CODE128" :height="50" :width="2" :displayValue="true"></barcode>
									</div>
								</div>
							</v-flex>

						</v-layout>

				

						<div class="no-print">
							<v-data-table :headers="item_headers" :items="order_items_bag" :loading="loading" class="elevation-1" item-key="order_item_id">
								<template v-slot:[`item.order_item_price`]="{ item }">
									<div>
										$ {{ item.order_item_price }}
									</div>
								</template>
								<template v-slot:[`item.total_item_price`]="{ item }">
									<div>
										$ {{ item.total_item_price }}
									</div>
								</template>
								<template v-slot:[`item.order_item_status`]="{ item }">
									<div>
										<v-chip v-if="item.order_item_status == 'pending'" color="orange" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'packed'" color="green" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'incomplete'" color="red" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'purchased'" color="blue" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'soldout'" color="black" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'postponed'" color="gray" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'unarrived'" color="gray" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'delivered'" color="gray" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'soldout_told'" color="pink accent-1" dark>{{ item.order_item_status }}</v-chip>
									</div>
								</template>

								<template v-slot:[`item.order_item_link`]="{ item }">
									<div>
										<a :href="item.order_item_link" target="_blank">
											<v-tooltip bottom>
												<template v-slot:activator="{ on }">
													<v-icon v-on="on" v-if="item.order_item_link != null">mdi-link</v-icon>
													<v-icon v-if="item.order_item_link == null" color="red" v-on="on">mdi-link</v-icon>
												</template>
												<span>{{ item.order_item_link }}</span>
											</v-tooltip>

										</a>
									</div>
								</template>
								<template v-slot:[`item.order_item_date`]="{ item }">
									<div>
										{{ new Date(item.order_item_date).toISOString().split('T')[0]}} {{ new Date(item.order_item_date).toISOString().split('T')[1].split('.')[0]}}
									</div>
								</template>
							</v-data-table>
						</div>

					</v-card-text>
					
				</v-card>
			</div>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>

</template>
<script>
	import requests from '../requests/order_items.request.js'
	import requestsOrder from '../requests/orders.request.js'
	import requestsPackBag from '../requests/packed_orders.request.js'
	import requestsPackedItems from '../requests/packed_items.request.js'
	import VueBarcode from 'vue-barcode';
	import requestsCus from '../requests/customers.request.js'



	export default {
		data() {
			return {
				order_items: {},
				total_bag_price: 0,
                qty:0,
				total_shipping_price: 0,
				total_price_dinar: 0,
				total_price: 0,
				packed_order_id_print: 0,
				arrival_date: new Date().toISOString().slice(0, 19).replace('T', ' '),
				search: '',
                customer: null,
				loading: false,
				loading_btn: false,
				showBagDialog: false,
				showUpdateDialog: false,
				showDeliveryPrice: false,
				delivery_price: 0,
				total_with_delivery: 0,
                customerList: [],
				selected_rows: [],
       
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_order_items: {},
				order: {},
				order_items_bag: [],
				delete_dialog: false,
				item_update: {},
				headers: [


					{
						text: this.$store.getters.language.data.order_items.order_sku_code,
						align: 'start',
						sortable: true,
						value: 'order_sku_code',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_price,
						align: 'start',
						sortable: true,
						value: 'order_item_price',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_status,
						align: 'start',
						sortable: true,
						value: 'order_item_status',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_quantity,
						align: 'start',
						sortable: true,
						value: 'order_item_quantity',
					},
					// {
					// 	text: "Total Price",
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'total_item_price',
					// },
					{
						text: this.$store.getters.language.data.order_items.order_item_note,
						align: 'start',
						sortable: true,
						value: 'order_item_note',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_link,
						align: 'start',
						sortable: true,
						value: 'order_item_link',
					},
					{
						text: this.$store.getters.language.data.orders.order_id,
						align: 'start',
						sortable: true,
						value: 'order_id',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_date,
						align: 'start',
						sortable: true,
						value: 'order_item_date',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_purchase_date,
						align: 'start',
						sortable: true,
						value: 'order_item_purchase_date',

					},
					// {
					// 	text: this.$store.getters.language.data.users.user_username,
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'order_item_username',
					// },
					{
						text: this.$store.getters.language.data.customers.customer_username,
						align: 'start',
						sortable: true,
						value: 'customer_username',
					},
					{
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'order_item_id',
					}
				],
				item_headers: [


					{
						text: this.$store.getters.language.data.order_items.order_sku_code,
						align: 'start',
						sortable: true,
						value: 'order_sku_code',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_price,
						align: 'start',
						sortable: true,
						value: 'order_item_price',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_quantity,
						align: 'start',
						sortable: true,
						value: 'order_item_quantity',
					},
					{
						text: "Total Price",
						align: 'start',
						sortable: true,
						value: 'total_item_price',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_status,
						align: 'start',
						sortable: true,
						value: 'order_item_status',
					},

					{
						text: this.$store.getters.language.data.order_items.order_item_link,
						align: 'start',
						sortable: true,
						value: 'order_item_link',
					},
					{
						text: this.$store.getters.language.data.orders.order_id,
						align: 'start',
						sortable: true,
						value: 'order_id',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_note,
						align: 'start',
						sortable: true,
						value: 'order_item_note',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_date,
						align: 'start',
						sortable: true,
						value: 'order_item_date',
					},
					// {
					// 	text: this.$store.getters.language.data.users.user_username,
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'order_item_username',
					// },
					// {
					// 	text: this.$store.getters.language.data.customers.customer_username,
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'customer_username',
					// },
					// {
					// 	text: this.$store.getters.language.data.actions,
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'order_item_id',
					// }
				],
			}
		},
		computed: {
	
			users() {
				return this.$store.getters.users_list
			},
			user() {
				return this.$store.getters.user
			},
			store_information() {
				return this.$store.getters.store_information_list
			},
       



		},
		components: {
			'barcode': VueBarcode
		},
		mounted() {
			// this.readOrderItems();
		},
		methods: {
            	readCustomers() {
				this.loading = true
				requestsCus.getAllCustomers().then(r => {
					if (r.status == 200) {
						this.customerList = r.data.rows
						// this.blocked_numbers = r.data.blocked_numbers
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Customers',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Customers',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			addOrderItemsList(packed_order_id) {
				var packed_order_id = packed_order_id
				this.loading_btn = true
				// create a array object add all item_id in order_items_bag with the name of item_id
				let data = []
				for (let i = 0; i < this.order_items_bag.length; i++) {
					// if the order_item_staus is not packed then add it to the data array object
					if (this.order_items_bag[i].order_item_status == 'packed') {
						data.push({
							packed_order_id: packed_order_id,
							order_item_id: this.order_items_bag[i].order_item_id,
                            delivered_by: this.user.user_id,
						})
                        this.qty = 1 + this.qty
					}
				}

				requestsPackedItems.createPackedItemsList({ list: data }).then(r => {
					if (r.status == 200) {
						// this.updateStatusPackedItems()
						this.order_items = {}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'OrderItems Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add OrderItems',
							color: 'error'
						}
					}
					this.loading_btn = false
					window.print();
                    window.location.reload();

				})

			},
			addPackedOrders() {
				this.loading_btn = true
				this.showDeliveryPrice = false
				this.total_with_delivery = parseInt(this.total_price_dinar) + parseInt(this.delivery_price)
				let data = {
					order_id: this.order[0].order_id,
					packed_order_total_price: this.total_price,
					packed_order_total_dinar: this.total_price_dinar,
					user_id: this.user.user_id,
					packed_order_delivery_price: this.delivery_price
					// packed_order_date: new Date().toISOString().slice(0, 19).replace('T', ' '),
				}
				requestsPackBag.createPackedOrders(data).then(r => {
					if (r.status == 200) {
						this.packed_order_id_print = r.data.new_data.packed_order_id
						this.addOrderItemsList(r.data.new_data.packed_order_id)
						this.data = {}
						this.delivery_price = 0
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'PackedOrders Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add PackedOrders',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			addPackedOrdersDirect() {
				this.loading_btn = true
				this.showDeliveryPrice = false
				this.total_with_delivery = parseInt(this.total_price_dinar) + parseInt(this.delivery_price)
				let data = {
					order_id: this.order[0].order_id,
					packed_order_total_price: this.total_price,
					packed_order_total_dinar: this.total_price_dinar,
					user_id: this.user.user_id,
					packed_order_delivery_price: this.delivery_price,
					packed_order_status: 'direct'
					// packed_order_date: new Date().toISOString().slice(0, 19).replace('T', ' '),
				}
				requestsPackBag.createPackedOrders(data).then(r => {
					if (r.status == 200) {
						this.packed_order_id_print = r.data.new_data.packed_order_id
						this.addOrderItemsList(r.data.new_data.packed_order_id)
						this.data = {}
						this.delivery_price = 0
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'PackedOrders Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add PackedOrders',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
                        this.loading = false
					})

			},
			addOrderItems() {
				this.loading_btn = true
				requests.createOrderItems(this.order_items).then(r => {
					if (r.status == 200) {
						this.order_items = {}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'OrderItems Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add OrderItems',
							color: 'error'
						}
					}
				})
					.finally(() => {
                        this.loading = false
						this.loading_btn = false
					})

			},
			

		

			readOrderItems(status) {
				this.loading = true
                let query = {
                    order_item_status: status,
                    order_sku_code: this.search,
                    customer_username : this.customer
                }
                if (status == 'all') {
                    query = {
                        order_sku_code: this.search,
                        customer_username : this.customer
                    }
                }
				requests.searchOrderItems(query).then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						// for each row change the order_item_date to minus 5 hours

						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read OrderItems',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read OrderItems',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},

			getAllItems() {
				this.loading = true
				requestsOrder.reportByOrderId("order_id", this.selected_order_items.order_id).then(r => {
					if (r.status == 200) {
						this.order = r.data.rows.order
						this.order_items_bag = r.data.rows.rows
						this.total_bag_price = r.data.rows.total_bag_price[0]['total_item_price']
						this.total_shipping_price = r.data.rows.total_bag_price[0]['total_shipping_price']
						this.total_price = r.data.rows.total_bag_price[0]['total_price']
						this.total_price_dinar = r.data.rows.total_bag_price[0]['total_price'] * this.store_information[0].dollar_price
						// this.total_price_dinar = r.data.rows.total_bag_price[0]['total_price'] * this.store_information[0].dollar_price remove whats after the dot
						this.total_price_dinar = this.total_price_dinar.toString().split(".")[0]
						// if this.total_price_dinar last 3 digits > 500 then add 1 to the first digit and replace the last 3 digits with 000 else replace the last 3 digits with 000
						if (this.total_price_dinar.toString().slice(-3) > 500) {
							this.total_price_dinar = parseInt(this.total_price_dinar.toString().slice(0, -3)) + 1 + '000'
						}
						else if (this.total_price_dinar.toString().slice(-3) == 500) {
							this.total_price_dinar = this.total_price_dinar
						}
						else {
							this.total_price_dinar = this.total_price_dinar.toString().slice(0, -3) + '000'
						}
						// if (this.total_price_dinar)
						console.log(r.data.rows)
                        this.qty = 0
						this.loading = false
						this.showOrders = true
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Orders',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Orders',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},

			selectOrderItems(i) {
				this.selected_order_items = i
				this.showBagDialog = true
				this.getAllItems()
			},
			selectOrderItemsForUpdate(i) {
				// take a copy of i into item_update
				this.item_update = Object.assign({}, i)
				this.showUpdateDialog = true
			},
			updateOrderItemStatus() {
				this.loading_btn = true
				let data = {
					order_item_id: this.item_update.order_item_id,
					order_item_status: this.item_update.order_item_status,
					order_id: this.item_update.order_id,
					order_item_link: this.item_update.order_item_link,
					order_item_note: this.item_update.order_item_note,
					order_item_price: this.item_update.order_item_price,
					order_item_quantity: this.item_update.order_item_quantity,
					order_sku_code: this.item_update.order_sku_code,
					user_id: this.user.user_id,

				}
                if (this.item_update.order_item_status == 'packed') {
                    data.packed_by = this.user.user_id
                }
				requests.updateOrderItems(data.order_item_id, data).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.map(m => {
							if (m.order_item_id == this.item_update.order_item_id) {
								m.order_item_status = this.item_update.order_item_status
							}
							this.showUpdateDialog = false
							return m
						})
						this.snackbar = {
							value: true,
							text: 'OrderItem Updated',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to update OrderItem',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to update OrderItem',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading_btn = false
					})
			},
			packBag() {
				this.loading_btn = true
				console.log(this.order)
				let data = {
					order_id: this.order[0].order_id,
					order_status: "Packed",
					order_packing_date: this.order[0].order_packing_date,
					order_shipping_price: this.order[0].order_shipping_price,
					order_total_price: this.total_price,
					order_note: this.order[0].order_note,
					user_id: this.order[0].user_id,
					customer_id: this.order[0].customer_id,
					order_date: new Date(this.order[0].order_date).toISOString().slice(0, 19).replace('T', ' '),
					order_packing_date: new Date().toISOString().slice(0, 19).replace('T', ' '),
				}
				console.log(data)
				requestsOrder.updateOrders(data.order_id, data).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.map(m => {
							if (m.order_id == this.selected_order_items.order_id) {
								m.order_status = "Packed"
							}
							this.showBagDialog = false
							return m
						})
						this.snackbar = {
							value: true,
							text: 'Order Packed',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to pack Order',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to pack Order',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading_btn = false
					})
			},
			// updateStatusPackedItems() {
			// 	this.loading_btn = true

			// 	let order_id = this.order[0].order_id

			// 	requests.updateStatus(order_id).then(r => {
			// 		if (r.status == 200) {
			// 			this.readOrderItems()
			// 			this.getAllItems()
			// 			console.log("update status")
			// 			this.snackbar = {
			// 				value: true,
			// 				text: 'Update Order Items',
			// 				color: 'success'
			// 			}
			// 		} else {
			// 			this.snackbar = {
			// 				value: true,
			// 				text: 'Fail to Update Order Items',
			// 				color: 'error'
			// 			}
			// 		}
			// 	})
			// 		.catch(e => {
			// 			this.snackbar = {
			// 				value: true,
			// 				text: 'Fail to pack Order',
			// 				color: 'error'
			// 			}
			// 		})
			// 		.finally(() => {
			// 			this.loading_btn = false
			// 		})


			// }
		},
	}
</script>
<style scoped>

	table,
	th,
	td {
		border: 1px solid black;
		border-collapse: collapse;
		margin: 0 auto;
	}
	th,
	td {
		padding: 5px;
		text-align: left;
	}
	@media (max-width: 600px) {
		th,
		td {
			font-size: 12px;
			padding: 4px;
		}
	}
	.table-container {
		overflow-x: auto; /* Enable horizontal scrolling if needed */
	}
</style>
